import useSiteMetadata from "../../components/SiteMetaData"
import Layout from "../../components/layout"
import Drift from "react-driftjs"
import ReactNotification, { store } from "react-notifications-component"
import SEO from "../../components/seo"
import VideoTutorial from "../../components/Index/VideoTutorial"
import React, { useEffect } from "react"
import FileSaver from 'file-saver'
import Download from "../../components/Download"

const DownloadPage = () => {
  const {driftApp, link} = useSiteMetadata()

  function download() {
    FileSaver.saveAs(link, "beatshine.zip")
    store.addNotification({
      title: '',
      message: 'Your Download will Start Shortly',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ["animated", "fadeIn"],
      animationOut: ['animated', "faeOut"],
      dismiss: {
        duration: 5000,
        onScreen: false
      }
    })
  }

/*  download()*/

  return(
    <Layout>
      <ReactNotification />
      <SEO title="Download"  path="/download"/>
      <Download />
    </Layout>
  )
}

export default DownloadPage